import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const PageContent = ({ children, className }) => (
  <div className={cx('page-content', className)}>{children}</div>
)

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

PageContent.defaultProps = {
  className: '',
}

export default PageContent
