/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'

import GooglePodcast from '../../images/svg/social/google-podcast.svg'
import Guides from '../../images/svg/social/guides.svg'
import Overcast from '../../images/svg/social/overcast.svg'
import Podcast from '../../images/svg/social/podcast.svg'
import RSSFeed from '../../images/svg/social/rss-feed.svg'
import Spotify from '../../images/svg/social/spotify.svg'
import Twitter from '../../images/svg/social/twitter.svg'
import Youtube from '../../images/svg/social/youtube.svg'

function SocialMenu() {
  return (
    <div className='social'>
      <a href='https://twitter.com/thebizbtc' target='_blank' rel='noreferrer'>
        <Twitter />
      </a>
      <a
        href='https://podcasts.apple.com/us/podcast/the-biz-with-john-carvalho/id1563114314'
        target='_blank'
        rel='noreferrer'
      >
        <Podcast />
      </a>
      <a
        href='https://www.youtube.com/c/BitcoinMagazine/search?query=the%20biz%20with%20john%20carvalho'
        target='_blank'
        rel='noreferrer'
      >
        <Youtube />
      </a>
      <a
        href='https://open.spotify.com/show/2u9V2OL0RbC94rThUMxJJx'
        target='_blank'
        rel='noreferrer'
      >
        <Spotify />
      </a>
      <a
        href='http://btcbiz.btc.libsynpro.com'
        target='_blank'
        rel='noreferrer'
      >
        <Overcast />
      </a>
      <a
        href='https://podcasts.google.com/feed/aHR0cHM6Ly9idGNiaXoubGlic3luLmNvbS9yc3M?sa=X&ved=0CAMQ4aUDahcKEwig3uuOio3xAhUAAAAAHQAAAAAQAg'
        target='_blank'
        rel='noreferrer'
      >
        <GooglePodcast />
      </a>
      <a
        href='https://bitcoinmagazine.com/tags/the-biz'
        target='_blank'
        rel='noreferrer'
      >
        <Guides />
      </a>
      <a
        href='http://btcbiz.btc.libsynpro.com/rss'
        target='_blank'
        rel='noreferrer'
      >
        <RSSFeed />
      </a>
    </div>
  )
}

export default SocialMenu
