import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import loadable from '@loadable/component'
import { Button } from '@ufx-ui/core'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import Logo from '../../images/logo-dark.svg'
import SocialMenu from '../SocialMenu'

const LoadableDialog = loadable(() => import('@ufx-ui/core'), {
  resolveComponent: components => components.Dialog,
})

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const menuList = (
    <>
      <Link activeClassName='active-link' to='/about'>
        About
      </Link>
      <Link activeClassName='active-link' to='/episodes'>
        Episodes
      </Link>
      <Link activeClassName='active-link' to='/faq'>
        FAQ
      </Link>
      <Link activeClassName='active-link' to='/contact'>
        Contact
      </Link>
    </>
  )

  return (
    <>
      <div className='header'>
        <Row>
          <Col xs={11} sm={4} className='logo'>
            <Link to='/'>
              <img src={Logo} alt='biz-logo' />
            </Link>
          </Col>
          <Col xs={1} className='mobile-menu visible-sm'>
            <Button minimal onClick={() => setShowMobileMenu(true)}>
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </Col>
          <Col
            smOffset={1}
            sm={7}
            mdOffset={2}
            md={6}
            bottom='xs'
            className='hidden-sm right'
          >
            <Col className='nav'>
              <Row between='xs'>{menuList}</Row>
            </Col>

            <Col className='social-wrapper'>
              <SocialMenu />
            </Col>
          </Col>
        </Row>
      </div>

      <LoadableDialog
        isOpen={showMobileMenu}
        onClose={() => setShowMobileMenu(false)}
      >
        <Col className='nav-mobile'>
          <>
            <Link activeClassName='active-link' to='/'>
              Home
            </Link>
            {menuList}
          </>
        </Col>
      </LoadableDialog>
    </>
  )
}

export default Header
